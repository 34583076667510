import React, { useState, useEffect } from "react";
import Network from '../../../models/Network.js';

import * as vega from 'vega';
import * as vegaLite from 'vega-lite';
import * as vl from 'vega-lite-api';
import { Handler } from 'vega-tooltip';
// import { json } from "d3";

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')

const colorScale = {
    domain: ['a passes', 'b fails', 'c does not apply'],
    range: ['#00FF00', '#e83838', '#bbb']
}

const config = {
    axis: {
        domain: false,
        tickColor: 'lightGray',
    },
    style: {
        'guide-label': {
            fontSize: 12,
            fill: '#000'
        },
        'guide-title': {
            fontSize: 18,
            fill: '#000'
        }
    },
    legend: {
        disable: true
    }
};

const pointSize = 400;

const reportPlot = vl.markPoint({ // defines the type of Mark being used
    // Mark properties
    filled: true,
    stroke: false,
    size: pointSize,
    opacity: 0.4
})
    .encode(
        vl.color().fieldN('applies_string')
            .title('Applies')
            // .legend({ title: 'this thing', titleAlign: 'right' })
            .legend({ disable: true })
            // .sort('descending')
            // .order().fieldQ('value')
            .scale(colorScale),
            // .if(brush, vl.color().fieldN('report_type').scale(scale).title('Report Type')),
        //   vl.size().fieldQ('precipitation').scale({domain: [-1, 50], range: [10, 500]}).title('Precipitation'),
        //   vl.size().fieldQ('response_time').title('Response Time'),
        //   vl.order().fieldQ('response_time').sort('descending'),
        vl.order().fieldQ('applies_string').sort('descending'), // this changes what points are on top
        vl.x().fieldT('date')
            // .timeUnit('monthdate') // make the time unit month and date - this gets rid of the time of day
            .timeUnit({ unit: 'monthdate', utc: false }) // Display month and date in local time
            // add scale -> padding: 20
            .scale({padding: pointSize/40})
            .axis({title: null}),
        //   vl.y().fieldT('creation_timestamp')
        vl.y().fieldT('date')
            // .timeUnit('utchours') // make display time of day by hour
            // .timeUnit('hours') // make display time of day by hour (NON UTC)
            .timeUnit({ unit: 'hours', utc: false }) // Display time of day in hours (local time)
            .scale({padding: pointSize/40})
            .axis({title: 'Time of Day', format: '%I %p'}),
        vl.tooltip().fieldN('value')
        // vl.tooltip().fieldT('unit_back_in_service')
        //     .timeUnit({ unit: 'monthdatehoursminutes', utc: false })
            // .timeUnit('hours')
    )
    .width(700) // sized for normal, 1080p with no zoom
    .height(250)
// end of reportPlot

const timeSeriesPlot = vl.markLine({ // defines the type of Mark being used
    point: true
})
    .encode(
        // vl.x().fieldQ('scene_to_destination_time')
        vl.color().value('#e83838'),
        vl.x().fieldT('date')
            // .scale({ zero: false })
            .title('2023 Whole Year')
            .scale({padding: pointSize/40})
            .timeUnit('month'), // bins the data by timeunit
            // .timeUnit('milliseconds')
            // .axis({ tickCount: 5 }),
        vl.y().fieldQ('value')
            // .scale({ zero: false })
            .aggregate('average')
            .title('Percent Compliance')
            // .axis({ tickCount: 6 }),
        // vl.color().fieldN('report_type')
        //     .title('Call Type')
        //     .scale(colorScale),
        // vl.tooltip().fieldN('report_id') // no tooltip for public graphs
    )
    .width(700) // sized for normal, 1080p with no zoom
    .height(250)
// end of timeSeriesPlot

// registration
vl.register(vega, vegaLite, {
    view: { renderer: 'svg' },
    init: view => { view.tooltip(new Handler().call); }
});

const GraphApiMeasureReportsDemo = ({ data, graphParams }) => {

    const [marks, setMarks] = useState(null);
    const [vegaView, setVegaView] = useState(null);

    // iterate through data, adding an 'applies_string' key
    // the value of this key for each data point will be derived from the 'applies' key of the given point
    // 'applies' can be true, false, or null, mapping to: 'a passes', 'b fails', 'c does not apply'
    for (let i = 0; i < data.length; i++) {
        let applies = data[i].applies;
        if (applies === true) {
            data[i].applies_string = 'a passes';
        } else if (applies === false) {
            data[i].applies_string = 'b fails';
        } else {
            data[i].applies_string = 'c does not apply';
        }
    }

    useEffect(() => {
        // setMarks(reportPlot
        setMarks(vl.vconcat(reportPlot, timeSeriesPlot)
            .data(data) // data set
            // .title('Reports Multimodal with Brush') // title of View
            .config(config)
            .autosize({contains: 'padding'})
        );
        console.log('marks are being set again')
    }, [data]);

    useEffect(() => {
        if (marks) {
            marks.render().then(view => {
                setVegaView(view);
                console.log('Vega view set again');
            }).catch(error => {
                console.error('Error rendering Vega view:', error);
            });
        }
    }, [marks]);

    return (
        <>
            {vegaView ? (
                // the View must be appended to the div
                <div ref={(div) => {
                    if (div && vegaView) {
                        div.innerHTML = ''; // Clear previous view
                        div.appendChild(vegaView); // Append the new view
                    }
                }} />
            ) : (
                // Otherwise, display a loading message
                <div>Loading...</div>
            )}
        </>
    );
};

export default GraphApiMeasureReportsDemo;