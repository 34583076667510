import React, { useState, useEffect } from "react";
import Network from '../../../models/Network.js';

import * as vega from 'vega';
import * as vegaLite from 'vega-lite';
import * as vl from 'vega-lite-api';
import { Handler } from 'vega-tooltip';
// import { json } from "d3";

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')

const dark = '#3e3c38';

const config = {
    axis: {
        domain: false,
        tickColor: 'lightGray',
    },
    style: {
        'guide-label': {
            fontSize: 12,
            fill: dark
        },
        'guide-title': {
            fontSize: 24,
            fill: dark,
            labelLimit: 0
        }
    }
};

// const colorScale = {
//     domain: ['general-emergent', 'hypoglycemia', 'respiratory', 'stroke', 'syncope', 'refusal', 'trauma'],
//     range: ['#E83838', '#8CF0C7', '#FFE347', '#4CC9F0', '#32CD32', '#D2B48C', '#000']
// }

const colorScale = {
    domain: ['general-emergent'],
    range: ['#E83838']
}

const viz = vl.markBar()
    .encode(
        // vl.x().fieldQ('scene_to_destination_time')
        vl.x().fieldN('crew_member_id')
            // .scale({ zero: false })
            .title('Reporting Crew Members')
            // .timeUnit('milliseconds')
            .axis({ tickCount: 3 }),
        vl.y().fieldQ('number_issues')
            // .scale({ zero: false })
            .title('Cumulative Report Issues')
            .axis({ tickCount: 6 }),
        vl.color().fieldN('report_type')
            .scale(colorScale)
            .title('Call Type')
            .legend({ disable: true }),
            // .legend({ orient: 'top', direction: 'vertical' }),
        vl.tooltip().fieldN('report_id')
    )

vl.register(vega, vegaLite, {
    view: { renderer: 'svg' },
    init: view => { view.tooltip(new Handler().call); }
});

const GraphReportsScatter = ({ graphParams }) => {

    const [jsonData, setJsonData] = useState(null);
    const [marks, setMarks] = useState(null);
    const [vegaView, setVegaView] = useState(null);

    // function that gets multiple reports from the server
    const getSeveralReports = async (desiredNumber, reportOffset) => {
        
        const url = URLS.GET_SEVERAL_REPORTS_ANALYTICS;
        const dict = {
            // uid: user.uid,
            uid: 4,
            number_of_reports: desiredNumber,
            offset: reportOffset,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {

            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });

        return responseData;
    };

    useEffect(() => {
        getSeveralReports(400, 0) // # reports, offset
            .then((data) => {
                console.log('data: ', data[0]);
                setJsonData(data);
                // console.log('json data set: ', jsonData);
                setMarks(viz
                    .data(data)
                    .width(graphParams && graphParams.width !== null ? graphParams.width : 500)
                    // .width(500)
                    // .height(window.innerHeight)
                    .height(graphParams && graphParams.height !== null ? graphParams.height : 450)
                    // .height(400)
                    // .autosize({ type: 'fit', contains: 'padding' })
                    .autosize({ type: 'fit' })
                    .config(config)
                    .transform(
                        {"filter": "datum.number_issues < 6"}, // rid of outliers
                        {"filter": "datum.number_issues > 0"},
                        // report_type: "general-emergent"
                        {"filter": "datum.report_type == 'general-emergent'"},
                        // {"filter": "datum."}
                        // one final filter removing reports older than 1 week old - bug, off by 1 month
                        {"filter": "datetime(datum.creation_timestamp) > datetime(2024, 9, 20)"}
                    )
                );
                // console.log('marks set: ', marks);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    useEffect(() => {
        if (marks) {
            marks.render().then(view => {
                setVegaView(view);
            }).catch(error => {
                console.error('Error rendering Vega view:', error);
            });
        }
    }, [marks]);

    return (
        <>
            {jsonData && vegaView ? (
                <div ref={(div) => {
                    if (div && vegaView && div.children.length === 0) {
                        div.appendChild(vegaView);
                    }
                }} />
            ) : (
                <div>Loading...</div>
            )}
        </>
    );
};

export default GraphReportsScatter;