import React, { useState, useEffect } from 'react';

import GraphModule from '../modules/GraphModule';
import MeasureGraphsModule from '../modules/MeasureGraphsModule';
import MeasureGraphsDemoModule from '../modules/MeasureGraphsDemoModule';
import './Box.css';

const QiMeasuresGraphsBox = ({user, isSmallScreen}) => {

    return (
        <div className={isSmallScreen ? "standard-box-screen-small" : "standard-box"}>

            <h1 className='box-title'> Agency QI Measures </h1>

            <MeasureGraphsModule
                user={user}
                isSmallScreen={isSmallScreen}
            />

        </div>
    );
};

export default QiMeasuresGraphsBox;