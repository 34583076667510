import React, { useState, useEffect } from 'react';

import ReviewGraphsModule from '../modules/ReviewGraphsModule';
import './Box.css';

const HomeGraphsBox = ({user, isSmallScreen}) => {

    return (
        <div className={isSmallScreen ? "standard-box-screen-small" : "standard-box"}>

            {/* <h1 className='box-title'> Week in Review </h1> */}
            <h4></h4>

            <ReviewGraphsModule
            user={user}
            isSmallScreen={isSmallScreen}
            />

        </div>
    );
};

export default HomeGraphsBox;