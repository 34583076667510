import React, { useState, useEffect } from 'react';
import Network from '../../../models/Network.js';
import ReactMarkdown from 'react-markdown'
// import ReactDom from 'react-dom'

import FilterListElement from '../list_elements/FilterListElement.js';
import GraphApiMeasureReports from '../graphics/GraphApiMeasureReports.js';
import GraphApiMeasureReportsInteractive from '../graphics/GraphApiMeasureReportsInteractive.js';
import TypeTag from '../tags/TypeTag.js';
import ImpactAttributeTag from '../tags/ImpactAttributeTag.js';
import './Module.css';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')
const ErrorCodes = require('../../../error-codes/error-codes.js')

const MeasureGraphsModule = ({user, isSmallScreen}) => {

    const [jsonData, setJsonData] = useState(null);
    const [measureMetadata, setMeasureMetadata] = useState(null);
    const [filterDict, setFilterDict] = useState(null);

    // const tempMeasure = "Run Times Recorded";
    const tempMeasure = "At Least 2 Sets of Vitals Taken";
    
    const graphParams = {
        width: 400,
        height: 400
    }

    // function that gets multiple reports from the server
    const getSeveralReportsMeasuresFiltered = async (desiredNumber, reportOffset, filterDict) => {
        
        const url = URLS.GET_SEVERAL_REPORTS_MEASURES_FILTERED;
        const dict = {
            uid: user.uid,
            number_of_reports: desiredNumber,
            offset: reportOffset,
            filter_dict: filterDict,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {

            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });

        return responseData;
    };

    const getMeasureMetadata = async (desiredMeasure) => {
        
        const url = URLS.GET_MEASURE_METADATA;
        const dict = {
            uid: user.uid,
            measure_name: desiredMeasure,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {

            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });

        return responseData;
    };

    // get report measures from the server
    useEffect(() => {
        // get the reports based on the current filterDict
        if (filterDict && filterDict.measureName) {
            getSeveralReportsMeasuresFiltered(5000, 0, filterDict) // max 5000 reports, no offset
                .then((data) => {
                    setJsonData(data); // TODO: add protection against API failure
                    console.log('Setting JSON data:', data);
                })
                .catch((error) => {
                    console.log('Error:', error);
                });
            getMeasureMetadata(filterDict.measureName)
                .then((data) => {
                    setMeasureMetadata(data); // TODO: add protection against API failure
                    console.log('Setting measure metadata:', data);
                })
        }
    }, [filterDict]);

    
    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>
            <FilterListElement
                filterState={
                    {
                        date: ["year"],
                        read: ["both"],
                        finished: ["both"]
                    }
                }
                dictUpdateHandler={setFilterDict}
                filterFields="analytics"
            />
            <div>
                {jsonData ? (
                    <h2 className='module-title'>{filterDict.measureName}</h2>
                ) : (
                    <div></div>
                )}
            </div>
            {/* {measureMetadata ? (
                <div>
                    <TypeTag reportType={measureMetadata[0].test_type} isButton={false}/>
                    <ImpactAttributeTag attributeType={measureMetadata[0].impact_attribute} isButton={false}/>
                </div>
            ) : (
                
                <div></div>
            )} */}
            <div>
                {measureMetadata ? (
                    <h4 className='module-subtitle'>{measureMetadata[0].issue_message}</h4>
                ) : (
                    <h4 className='module-subtitle'>Select a measure above to visualize it.</h4>
                )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                {jsonData ? (
                    <div>
                        {/* <GraphApiMeasureReportsInteractive data={jsonData} graphParams={graphParams}/> */}
                        <GraphApiMeasureReports data={jsonData} graphParams={graphParams}/>
                    </div>
                ) : (
                    <div></div>
                )}
            </div>
            <div style={{ textAlign: 'left' }}>
                {measureMetadata ? (
                    <ReactMarkdown>{measureMetadata[0].long_description}</ReactMarkdown>
                ) : (
                    <div></div>
                )}
            </div>
        </div>
    );
}

export default MeasureGraphsModule;