import React from 'react';
import './Tag.css';

const ImpactAttributeTag = ({ attributeType, isButton, onButtonClick }) => {
    const tagNames = {
        "billing": "Billing",
        "documentation": "Documentation",
        "patient-care": "Patient Care",
        "measure": "Measure",
        "quant-measure": "Quantitative Measure"
    };

    return (
        <div>
            {isButton ? (
                <button className={`tag-button ${attributeType}`}>
                    {tagNames[attributeType]}
                </button>
            ) : (
                <div className={`tag ${attributeType}`}>
                    {tagNames[attributeType]}
                </div>
            )}
        </div>
    );
};

export default ImpactAttributeTag;
