import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import Network from '../../../models/Network.js';

import * as vega from 'vega';
import * as vegaLite from 'vega-lite';
import * as vl from 'vega-lite-api';
import { Handler } from 'vega-tooltip';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')

const brush = vl.selectInterval().encodings('x');
const click = vl.selectMulti().encodings('color');

const colorScale = {
    domain: ['Emergent', 'Cancelled', 'Non-Acute', 'Refusal'],
    range: ['#e83838', '#222', '#22b922', '#FFCC00']
}

const shapeScale = {
    domain: ['Unread', 'Read', 'Finished'],
    range: ['circle', 'cross', 'ring'] // finished not visible
}

const config = {
    axis: {
        domain: false,
        tickColor: 'lightGray',
    },
    style: {
        'guide-label': {
            fontSize: 12,
            fill: '#000'
        },
        'guide-title': {
            fontSize: 18,
            fill: '#000'
        }
    },
    legend: {
        disable: true
    }
};

const pointSize = 500;

const GraphApiMultimodal = ({ data, graphParams }) => {
    const navigate = useNavigate();
    const containerRef = useRef(null);
    const [vegaSpec, setVegaSpec] = useState(null);

    // -------------------------------------------------------------------------------------

    const plot1 = vl.markPoint({ // defines the type of Mark being used
        // Mark properties
        filled: true,
        stroke: false,
        size: pointSize,
        opacity: 0.4
    })
        .data(data) // data set
        .encode(
            // vl.shape().fieldN('status').type('nominal'),
            vl.color().value('lightgray')
                .if(brush, vl.color().fieldN('report_type').scale(colorScale).title('Report Type')),
            vl.shape().fieldN('status').scale(shapeScale),
            vl.x().fieldT('unit_back_in_service')
                .timeUnit({ unit: 'monthdateyear', utc: false }) // Display month and date in local time
                .scale({padding: pointSize/40})
                .axis({title: 'Date', format: '%b the %dth'}),
            vl.y().fieldT('unit_back_in_service')
                .timeUnit({ unit: 'hoursminutes', utc: false }) // Display time of day in hours (local time)
                .scale({padding: pointSize/40})
                .axis({title: 'Time of Day', format: '%I %p'}),
            vl.tooltip().fieldN('incident_number')
        )
        // .spec({
        //     params: [
        //         {
        //             name: "highlight",
        //             select: { type: "point", on: "pointerover" }
        //         },
        //         {
        //             name: "select",
        //             select: "point"
        //         }
        //     ]
        // }) // Insert params here in raw Vega-Lite JSON format
        .width(700) // sized for normal, 1080p with no zoom
        // .height(600)
        .height(400)
        .select(brush)
        .transform(vl.filter(click));
    
    const plot2 = vl.markBar()
        .data(data) // data set
        .encode(
          vl.color().value('lightgray')
            .if(click, vl.color().fieldN('report_type').scale(colorScale).title('Report Type')),
          vl.x().count()
            .axis({title: 'Number of Reports'}),
          vl.y().fieldN('report_type').title('Type').scale({domain: colorScale.domain})
        )
        .width(700)
        .select(click)
        .transform(vl.filter(brush));

    // -------------------------------------------------------------------------------------

    useEffect(() => {
        const vegaLiteSpec = vl.vconcat(plot1, plot2)
            .config(config)
            .toSpec(); // compile to vega-lite spec
        setVegaSpec(vegaLite.compile(vegaLiteSpec).spec); // compile to vega spec
    }, [data]);

    useEffect(() => {
        if (vegaSpec) {
            const runtime = vega.parse(vegaSpec);
            const view = new vega.View(runtime)
                .renderer("svg") // imputSpec is null
                .initialize(containerRef.current)
                .hover() // Enable hover interaction
                .tooltip(new Handler().call); // Add Vega Tooltip handler
    
            view.addEventListener("click", (event, item) => {
                console.log("Point clicked");
                // console.log(item.datum.report_id); // Access the clicked data point
                if (item.datum.report_id !== undefined && item.datum.report_id !== null) {
                    navigate('/report/' + item.datum.report_id); // navigate to report page
                }
            });

            view.run();

            return () => {
                view.finalize();
            };
        }
    }, [vegaSpec]);

    return (
        <div>
            Legend: + Read, o Unread
            <div ref={containerRef} />
        </div>
    );
};

export default GraphApiMultimodal;