import React, { useState, useEffect } from "react";
import Network from '../../../models/Network.js';

import * as vega from 'vega';
import * as vegaLite from 'vega-lite';
import * as vl from 'vega-lite-api';
import { Handler } from 'vega-tooltip';
// import { json } from "d3";

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')

const dark = '#3e3c38';

const config = {
    axis: {
        domain: false,
        tickColor: 'lightGray',
    },
    style: {
        'guide-label': {
            fontSize: 16,
            fill: dark
        },
        'guide-title': {
            fontSize: 24,
            fill: dark,
            labelLimit: 0
        }
    }
};

// const colorScale = {
//     domain: ['general-emergent', 'hypoglycemia', 'respiratory', 'stroke', 'syncope', 'trauma'],
//     range: ['#E83838', '#8CF0C7', '#FFE347', '#4CC9F0', '#32CD32', '#000']
// }

const colorScale = {
    domain: ['general-emergent'],
    range: ['#E83838']
}

// setting up marks and channels
const viz = vl.markPoint({ filled: true, stroke: false, size: 500, opacity: 0.25 })
    .encode(
        // vl.x().fieldQ('scene_to_destination_time')
        vl.x().fieldQ('transport_time_m')
            // .scale({ zero: false })
            .title('Transport Time (minutes)')
            // .timeUnit('milliseconds')
            .axis({ tickCount: 5 }),
        vl.y().fieldQ('on_scene_to_end_odom')
            // .scale({ zero: false })
            .title('Transport Distance (miles)'),
            // .axis({ tickCount: 6 }),
        vl.color().fieldN('report_type')
            .title('Call Type')
            // .legend({ title: 'this thing', titleAlign: 'right' })
            .legend({ disable: true })
            .scale(colorScale),
        vl.tooltip().fieldN('report_id') // no tooltip for public graphs
    )

// registration
vl.register(vega, vegaLite, {
    view: { renderer: 'svg' },
    init: view => { view.tooltip(new Handler().call); }
});

const GraphReportsScatter = ({ graphParams }) => {

    const [jsonData, setJsonData] = useState(null);
    const [marks, setMarks] = useState(null);
    const [vegaView, setVegaView] = useState(null);

    // function that gets multiple reports from the server
    const getSeveralReports = async (desiredNumber, reportOffset) => {
        
        const url = URLS.GET_SEVERAL_REPORTS_ANALYTICS;
        const dict = {
            // uid: user.uid,
            uid: 4,
            number_of_reports: desiredNumber,
            offset: reportOffset,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {

            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });

        return responseData;
    };

    useEffect(() => {
        getSeveralReports(400, 0) // # reports, offset
            .then((data) => {
                console.log('data: ', data);
                setJsonData(data);
                console.log('json data set: ', jsonData);
                // console.log('graphParams: ', graphParams);
                setMarks(viz
                    .data(data)
                    .width(graphParams && graphParams.width !== null ? graphParams.width : 500)
                    // .width(500)
                    .height(graphParams && graphParams.height !== null ? graphParams.height : 450)
                    // .height(450)
                    .autosize({ type: 'fit' })
                    .config(config)
                    .transform(
                        {
                        "calculate": "datum.scene_to_destination_time/60000",
                        "as": "transport_time_m"
                        },
                        {"filter": "datum.on_scene_to_end_odom > 0"},
                        {"filter": "datum.transport_time_m < 60"},
                        // one final filter removing reports older than 1 week old - bug, off by 1 month
                        {"filter": "datetime(datum.creation_timestamp) > datetime(2024, 9, 20)"}
                    )
                );
                // console.log('marks set: ', marks);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    useEffect(() => {
        if (marks) {
            marks.render().then(view => {
                setVegaView(view);
            }).catch(error => {
                console.error('Error rendering Vega view:', error);
            });
        }
    }, [marks]);

    return (
        <>
            {jsonData && vegaView ? (
                <div ref={(div) => {
                    if (div && vegaView && div.children.length === 0) {
                        div.appendChild(vegaView);
                    }
                }} />
            ) : (
                <div>Loading...</div>
            )}
        </>
    );
};

export default GraphReportsScatter;