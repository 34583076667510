import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Network from '../../../models/Network.js'; 
import './LoginPage.css';
import User from '../../../models/User.js';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const bypass = process.env.REACT_APP_BYPASS_LOGIN;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')
const ErrorCodes = require('../../../error-codes/error-codes.js')


function Login({onLogin}) {
    const navigate = useNavigate();

    // bypass login - USE FOR DEVELOPMENT ONLY //
    if (bypass === 'true') {
        onLogin(new User(4,'billing@cadmusanalytics.com','Brad Greer','CADMUS','CADMUS_QA,CADMUS_QI,CADMUS_AUDITOR,ADMIN_SUB'));
        navigate('/home');
    }
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [MFACode, setMFACode] = useState('');
    const [loginContainerVisible, setLoginContainerVisible] = useState(true);
    const [errorPopupVisible, setErrorPopupVisible] = useState(false);
    const [MFAContainerVisible, setMFAContainerVisible] = useState(false);
    var [errorMessage, setErrorMessage] = useState(''); // New state for error message

    const handleLogin = async () => {
        closeErrorPopup()
        const url = URLS.LOGIN_URL
        const dict = {
            email: email,
            password: password,
            CLIENT_ACCESS_KEY: key
        }

        // // create a user - TODO: make this an admin command rather than this block of code
        // const url = URLS.CREATE_USER_URL
        // const dict = {
        //     email: 'billing@cadmusanalytics.com',
        //     agency_name: 'CADMUS',
        //     password: 'Clapstone0423',
        //     name_of_registrant: 'Brad Greer',
        //     CLIENT_ACCESS_KEY: key
        // }

        // // create a user - TODO: make this an admin command rather than this block of code
        // const url = URLS.CREATE_USER_URL
        // const dict = {
        //     email: 'bradalangreer@gmail.com',
        //     agency_name: 'NGHS',
        //     password: 'Clapstone0423',
        //     name_of_registrant: 'Brad Greer',
        //     CLIENT_ACCESS_KEY: key
        // }

        // // create a user - TODO: make this an admin command rather than this block of code
        // const url = URLS.CREATE_USER_URL
        // const dict = {
        //     email: 'rdavis@habershamga.com',
        //     agency_name: 'HABERSHAM',
        //     password: 'r0nn13d@v1s',
        //     name_of_registrant: 'Ronnie',
        //     CLIENT_ACCESS_KEY: key
        // }

        // // create a user - TODO: make this an admin command rather than this block of code
        // const url = URLS.CREATE_USER_URL
        // const dict = {
        //     email: 'robert.scott1@nghs.com',
        //     agency_name: 'NGHS',
        //     password: 'nghsadminpass',
        //     name_of_registrant: 'Bob',
        //     CLIENT_ACCESS_KEY: key
        // }


        // // create a user - TODO: make this an admin command rather than this block of code
        // const url = URLS.CREATE_USER_URL
        // const dict = {
        //     email: 'marlana.crews@nghs.com',
        //     agency_name: 'NGHS',
        //     password: 'paramedic911$',
        //     name_of_registrant: 'Marlana',
        //     CLIENT_ACCESS_KEY: key
        // }


        // POST request to login_url to attempt login
        await Network.post(url, dict, async (response) => {
            
            const responseData =  response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) { // if login is successful:
                
                // create User object to serve as session token
                const responseJSON = response.data[0]
                onLogin(User.createFromJSON(responseJSON)) // hands User off in callback to App.js
                
                // hide login window
                clearInput()
                hideLoginWindow()

                // show MFA window
                setMFAContainerVisible(true) // delte after below function is working

                // "your MFA code is being generated"
                const url = URLS.CREATE_MFA_URL
                await Network.post(url, dict, (response) => { // MFA code creation now done
                    if (Network.checkValidStatusAndResponse(response)) {
                        setMFAContainerVisible(true)
                    
                    } else {
                        console.log("Server Error")
                        setErrorMessage("Cannot connect to servers.")
                        setErrorPopupVisible(true)
                    }
                })

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")
                setErrorMessage("Cannot connect to servers.")
                setErrorPopupVisible(true)

            } else if (extractedErrorCode == ErrorCodes.USER_NOT_FOUND || ErrorCodes.PASSWORD_INCORRECT) {
                console.log("Failed Login Error")
                setErrorMessage("Your login information is incorrect.")
                setErrorPopupVisible(true)

            } else {console.log('No Valid Response or Error')}
        })            
    };

    const handleMFACheck = async () => {
        const url = URLS.VALIDATE_MFA_URL
        const dict = {
            email: email,
            MFACode: MFACode,
            CLIENT_ACCESS_KEY: key
        }

        // POST request to validate MFA code
        await Network.post(url, dict, async (response) => {

            const responseData =  response.data;
            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                navigate('/home');

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")
                setErrorMessage("Cannot connect to servers.")
                setErrorPopupVisible(true)

            } else if (extractedErrorCode == ErrorCodes.CODE_NOT_VALID) {
                console.log("MFA Timeout Error")
                setErrorMessage("Your MFA code has expired. Please reload and log back in.")
                setErrorPopupVisible(true)

            } else {console.log('No Valid Response or Error')}
        })
    }

    const hideLoginWindow = () => {
        setLoginContainerVisible(false);
    }

    const hideMFAWindow = () => {
        setMFAContainerVisible(false);
    };

    const closeErrorPopup = () => {
        setErrorPopupVisible(false);
    };

    const clearInput = () => {
        // setEmail("");
        setPassword("");
    };

    return (
        <div className="login-container">
            <img src={require('../../../images/logo-dark-QA.png')} alt="Logo" className="logo_login"/>
            
            {/* Login Content */}
            {loginContainerVisible && (
            <div>
            <div className="input-container">
                <input
                type="email"
                placeholder="Email"
                className="email-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                />
            </div>
            <div className="input-container">
                <input
                type="password"
                placeholder="Password"
                className="password-input"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                />
            </div>
            <button className="login-button" onClick={handleLogin}>
                Login
            </button>
            </div>
            )}
            
            {/* MFA Content */}
            {MFAContainerVisible && (
                <div>
                <div className="input-container">
                    <input
                    type="password"
                    placeholder="Check your email for MFA code"
                    className="password-input"
                    value={MFACode}
                    onChange={(e) => setMFACode(e.target.value)}
                    />
                </div>
                <button className="login-button" onClick={handleMFACheck}>
                    Verify MFA Code
                </button>
                </div>
            )}

            {/* Error Popup */}
            {errorPopupVisible && (
                <div className="error-popup">
                    <p>{errorMessage} For support email: <a href="mailto:contact@cadmusanalytics.com">contact@cadmusanalytics.com</a>.</p>
                    <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <button onClick={closeErrorPopup}>Close</button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Login;