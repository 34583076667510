import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import Network from '../../../models/Network.js';

import * as vega from 'vega';
import * as vegaLite from 'vega-lite';
import * as vl from 'vega-lite-api';
import { Handler } from 'vega-tooltip';
// import { json } from "d3";

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')

const colorScale = {
    domain: ['a passes', 'c fails', 'b does not apply'],
    range: ['#00FF00', '#e83838', '#bbb']
}

const config = {
    axis: {
        domain: false,
        tickColor: 'lightGray',
    },
    style: {
        'guide-label': {
            fontSize: 12,
            fill: '#000'
        },
        'guide-title': {
            fontSize: 18,
            fill: '#000'
        }
    },
    legend: {
        disable: true
    }
};

const pointSize = 150;




// registration
vl.register(vega, vegaLite, {
    view: { renderer: 'svg' },
    init: view => { view.tooltip(new Handler().call); }
});

const GraphApiMeasureReportsInteractive = ({ data, graphParams }) => {
    const navigate = useNavigate();
    const containerRef = useRef(null);
    const [vegaSpec, setVegaSpec] = useState(null);

    const [marks, setMarks] = useState(null);
    const [vegaView, setVegaView] = useState(null);

    for (let i = 0; i < data.length; i++) {
        let result = data[i].result;
        if (result === 1) {
            data[i].applies_string = 'a passes';
        } else if (result === 0) {
            data[i].applies_string = 'c fails';
        } else {
            data[i].applies_string = 'b does not apply';
        }
    }

    // -------------------------------------------------------------------------------------


    const reportPlot = vl.markPoint({ // defines the type of Mark being used
        // Mark properties
        filled: true,
        stroke: false,
        size: pointSize,
        opacity: 0.75
    })
        .data(data) // data set
        .transform(
            {"filter": "datetime(datum.unit_back_in_service) > datetime(2024, 8, 6)"}, // filter out data before this date (off by a month for some reason)
            {
                // Add a timeUnit transformation to extract month
                timeUnit: 'monthyear',
                field: 'unit_back_in_service',
                as: 'month_creation'
            },
            {
                // Aggregate Transform
                joinaggregate: [
                    {
                        op: 'mean',
                        field: 'result',
                        as: 'aggregate_percent'
                    }
                ],
                groupby: ['month_creation'] // Group by pre-transformed month field
            }
            )
        .encode(
            vl.color().fieldN('applies_string')
                .title('Result')
                // .legend({ title: 'this thing', titleAlign: 'right' })
                .legend({ disable: true })
                // .sort('descending')
                // .order().fieldQ('value')
                .scale(colorScale),
                // .if(brush, vl.color().fieldN('report_type').scale(scale).title('Report Type')),
            //   vl.size().fieldQ('precipitation').scale({domain: [-1, 50], range: [10, 500]}).title('Precipitation'),
            //   vl.size().fieldQ('response_time').title('Response Time'),
            //   vl.order().fieldQ('response_time').sort('descending'),
            vl.order().fieldQ('applies_string').sort('ascending'), // this changes what points are on top
            vl.x().fieldT('unit_back_in_service')
                // .timeUnit('monthdate') // make the time unit month and date - this gets rid of the time of day
                .timeUnit({ unit: 'monthdateyear', utc: false }) // Display month and date in local time
                // add scale -> padding: 20
                .scale({padding: pointSize/40})
                .axis({title: null}),
            //   vl.y().fieldT('creation_timestamp')
            vl.y().fieldT('unit_back_in_service')
                // .timeUnit('utchours') // make display time of day by hour
                // .timeUnit('hours') // make display time of day by hour (NON UTC)
                .timeUnit({ unit: 'hoursminutes', utc: false }) // Display time of day in hours (local time)
                .scale({padding: pointSize/40})
                .axis({title: 'Time of Day', format: '%I %p'}),
            vl.tooltip().fieldN('incident_number')
            // vl.tooltip().fieldT('unit_back_in_service')
            //     .timeUnit({ unit: 'monthdatehoursminutes', utc: false })
                // .timeUnit('hours')
        )
        .width(700) // sized for normal, 1080p with no zoom
        .height(250)
    // end of reportPlot
    
    const timeSeriesPlot = vl.markLine({ // defines the type of Mark being used
        point: { filled: true, size: pointSize }
    })
        .data(data) // data set
        .transform(
            {"filter": "datetime(datum.unit_back_in_service) > datetime(2024, 8, 6)"}, // filter out data before this date (off by a month for some reason)
            {
                // Add a timeUnit transformation to extract month
                timeUnit: 'monthyear',
                field: 'unit_back_in_service',
                as: 'month_creation'
            },
            {
                // Aggregate Transform
                joinaggregate: [
                    {
                        op: 'mean',
                        field: 'result',
                        as: 'aggregate_percent'
                    }
                ],
                groupby: ['month_creation'] // Group by pre-transformed month field
            }
            )
        .encode(
            // vl.x().fieldQ('scene_to_destination_time')
            vl.color().value('#e83838'),
            vl.x().fieldT('month_creation')
                // .scale({ zero: false })
                .title('Time Span')
                // .scale({padding: pointSize/40})
                .timeUnit('monthyear'), // bins the data by timeunit
                // .timeUnit('milliseconds')
                // .axis({ tickCount: 5 }),
            vl.y().fieldQ('result')
                // .scale({ zero: false })
                .aggregate('mean')
                .title('Percent Compliance'),
                // .axis({ tickCount: 6 }),
            // vl.color().fieldN('report_type')
            //     .title('Call Type')
            //     .scale(colorScale),
            vl.tooltip().fieldN('aggregate_percent') // no tooltip for public graphs
        )
        .width(700) // sized for normal, 1080p with no zoom
        .height(250)
    // end of timeSeriesPlot

    

    // -------------------------------------------------------------------------------------

    useEffect(() => {
        const vegaLiteSpec = vl.vconcat(reportPlot, timeSeriesPlot)
            .config(config)
            .toSpec(); // compile to vega-lite spec
        setVegaSpec(vegaLite.compile(vegaLiteSpec).spec); // compile to vega spec
    }, [data]);

    useEffect(() => {
        if (marks) {
            marks.render().then(view => {
                setVegaView(view);
                console.log('Vega view set again');
            }).catch(error => {
                console.error('Error rendering Vega view:', error);
            });
        }
    }, [marks]);


    return (
        <div>
            Legend: + Read, o Unread
            <div ref={containerRef} />
        </div>
    );
};

export default GraphApiMeasureReportsInteractive;