import React, { useState, useEffect } from 'react';
import Network from '../../../models/Network.js';

import FilterListElement from '../list_elements/FilterListElement.js';
import GraphApiMeasureReportsDemo from '../graphics/GraphApiMeasureReportsDemo.js';
import './Module.css';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')
const ErrorCodes = require('../../../error-codes/error-codes.js')

// generating an array of JSONs representing fake data, where num is the number of JSONs to generate
const generateFakeData = (num) => {
    let data = [];
    // each JSON will have a 'date' field with a random date in the past year with ISO 8601 format
    // each JSON will have a 'value' field with a random number between 0 and 100
    // each JSON will have an 'applies' field that can be either 'passes', 'fails', or 'does not apply'
    // 80% of JSONs will have 'does not apply', 14% will have 'passes', and 6% will have 'fails'
    for (let i = 0; i < num; i++) {
        let date = new Date(Date.now() - Math.floor(Math.random() * 31536000000)).toISOString();
        let value = Math.floor(Math.random() * 100);
        let applies;
        let rand = Math.random();
        if (rand < 0.8) {
            applies = null;
        } else if (rand < 0.94) {
            applies = true;
        } else {
            applies = false;
        }
        data.push({date: date, value: value, applies: applies});
    }
    return data;
}

const MeasureGraphsDemoModule = ({user, isSmallScreen}) => {

    const [jsonData, setJsonData] = useState(null);
    const [filterDict, setFilterDict] = useState(null);
    
    const graphParams = {
        width: 400,
        height: 400
    }

    // function that gets multiple reports from the server
    const getSeveralReportsFiltered = async (desiredNumber, reportOffset, filterDict) => {
        
        const url = URLS.GET_SEVERAL_REPORTS_FILTERED;
        const dict = {
            uid: user.uid,
            number_of_reports: desiredNumber,
            offset: reportOffset,
            filter_dict: filterDict,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {

            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });

        return responseData;
    };

    // get reports from the server
    useEffect(() => {
        // get the reports based on the current filterDict
        if (filterDict) {
            getSeveralReportsFiltered(1000, 0, filterDict) // max 1000 reports, no offset
                .then((data) => {
                    // setJsonData(data); // TODO: add protection against API failure
                    setJsonData(generateFakeData(400));
                    console.log('Setting JSON data:', data);
                })
                .catch((error) => {
                    console.log('Error:', error);
                });
        }
    }, [filterDict]);

    
    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>
            <FilterListElement
                filterState={
                    {date: ['year']}
                }
                dictUpdateHandler={setFilterDict}
                filterFields="analytics"
            />
            <div>
                <h2 className='module-title'>GCS Documented for Trauma Pt</h2>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                {jsonData ? (
                    <div>
                        <GraphApiMeasureReportsDemo data={jsonData} graphParams={graphParams}/>
                    </div>
                ) : (
                    <div>Loading...</div>
                )}
                {/* <div>
                    <GraphReportsScatter graphParams={graphParams}/>
                </div>
                <div>
                    <GraphReportsBar graphParams={graphParams}/>
                </div> */}
                {/* <div>
                    <GraphReportsTS graphParams={graphParams}/>
                </div>
                <div>
                    <GraphReportsScatter graphParams={graphParams}/>
                </div> */}
            </div>
        </div>
    );
}

export default MeasureGraphsDemoModule;