const BASE_URL = process.env.REACT_APP_API

// user-data
const CREATE_USER_URL = BASE_URL + "/users/create_user"
const LOGIN_URL = BASE_URL + "/users/login_with_email"
const LOGOUT_URL = BASE_URL + "/users/logout"
const CREATE_MFA_URL = BASE_URL + "/users/create_mfa"
const VALIDATE_MFA_URL = BASE_URL + "/users/validate_mfa"

const STORE_CUSTOMER_FORM = BASE_URL + "/public/store_customer_form"
const SEND_CONTACT_EMAIL = BASE_URL + "/public/send_contact_email"

const GET_SEVERAL_REPORTS = BASE_URL + "/reports/get_several_reports"
const GET_SEVERAL_REPORTS_FILTERED = BASE_URL + "/reports/get_several_reports_filtered"
const GET_SEVERAL_REPORTS_MEASURES_FILTERED = BASE_URL + "/reports/get_several_reports_measures_filtered"
const GET_SEVERAL_REPORTS_ANALYTICS = BASE_URL + "/reports/get_several_reports_analytics"
const GET_ONE_REPORT = BASE_URL + "/reports/get_one_report"
const GET_ONE_REPORT_AI_FLAGS = BASE_URL + "/reports/get_one_report_ai_flags"
const GET_ONE_REPORT_TIMES = BASE_URL + "/reports/get_one_report_times"
const GET_ONE_REPORT_CHECKS = BASE_URL + "/reports/get_one_report_checks"
const GET_MEASURE_METADATA = BASE_URL + "/reports/get_measure_metadata"
const GET_MEDICS_FOR_USER = BASE_URL + "/reports/get_all_agency_medics"
const MARK_REPORT_READ_UNREAD = BASE_URL + "/reports/mark_report_read_unread"
const UPDATE_REPORT_STATUS = BASE_URL + "/reports/update_report_status"
const UPDATE_CHECK_OVERRIDE = BASE_URL + "/reports/update_check_override"
const UPDATE_AI_FLAG_OVERRIDE = BASE_URL + "/reports/update_ai_flag_override"

// soap-data
const SUBMIT_DATA_URL = BASE_URL + "/submitData"

module.exports = {
    CREATE_USER_URL,
    LOGIN_URL,
    LOGOUT_URL,
    CREATE_MFA_URL,
    VALIDATE_MFA_URL,
    STORE_CUSTOMER_FORM,
    SEND_CONTACT_EMAIL,
    GET_SEVERAL_REPORTS,
    GET_SEVERAL_REPORTS_FILTERED,
    GET_SEVERAL_REPORTS_MEASURES_FILTERED,
    GET_SEVERAL_REPORTS_ANALYTICS,
    GET_ONE_REPORT,
    GET_ONE_REPORT_AI_FLAGS,
    GET_ONE_REPORT_TIMES,
    GET_ONE_REPORT_CHECKS,
    GET_MEASURE_METADATA,
    GET_MEDICS_FOR_USER,
    MARK_REPORT_READ_UNREAD,
    UPDATE_REPORT_STATUS,
    UPDATE_CHECK_OVERRIDE,
    UPDATE_AI_FLAG_OVERRIDE,
    SUBMIT_DATA_URL
}