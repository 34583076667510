import React from "react";

import "./LandingTitle.css";

const LandingTitle = () => {
    
    const graphParams = {
        width: 400,
        height: 400
    }
    
    return (
        <div className="landingTitle">
            <div className="content row">
                <div className="col text">
                    <h1>Upgrade Your EMS Quality Workflow Today</h1>
                    
                    {/* <a className="demo-btn" href="#demo"> */}
                    <a className="demo-btn" href="#demos">
                        View a demo
                        <i className="fa-solid fa-chevron-right"></i>
                    </a>
                    
                </div>
                <div className="col graphs">
                    <img className="front" src="https://images.unsplash.com/photo-1579037005241-a79202c7e9fd?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="graph"/>
                </div>
            </div>
        </div>
    )
}

export default LandingTitle;