import React, { useState, useEffect } from 'react';
import Network from '../../../models/Network.js';

import FilterListElement from '../list_elements/FilterListElement.js';
import GraphApiMeasureReports from '../graphics/GraphApiMeasureReports.js';
import './Module.css';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')
const ErrorCodes = require('../../../error-codes/error-codes.js')

// generating an array of JSONs representing fake data, where num is the number of JSONs to generate
const generateFakeData = (num) => {
    let data = [];
    for (let i = 0; i < num; i++) {
        let date = new Date(Date.now() - Math.floor(Math.random() * 31536000000)).toISOString();
        let value = Math.floor(Math.random() * 100);
        let applies;
        let rand = Math.random();
        if (rand < 0.8) {
            applies = 'c does not apply';
        } else if (rand < 0.94) {
            applies = 'a passes';
        } else {
            applies = 'b fails';
        }
        data.push({date: date, value: value, applies: applies});
    }
    return data;
}

const MeasureGraphsModule = ({user, isSmallScreen}) => {

    const [jsonData, setJsonData] = useState(null);
    const [filterDict, setFilterDict] = useState(null);

    // const tempMeasure = "Run Times Recorded";
    const tempMeasure = "At Least 2 Sets of Vitals Taken";
    
    const graphParams = {
        width: 400,
        height: 400
    }

    // function that gets multiple reports from the server
    const getSeveralReportsMeasuresFiltered = async (desiredNumber, reportOffset, filterDict, desiredMeasure) => {
        
        const url = URLS.GET_SEVERAL_REPORTS_MEASURES_FILTERED;
        const dict = {
            uid: user.uid,
            number_of_reports: desiredNumber,
            offset: reportOffset,
            measure_name: desiredMeasure,
            filter_dict: filterDict,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {

            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });

        return responseData;
    };

    // get report measures from the server
    useEffect(() => {
        // get the reports based on the current filterDict
        if (filterDict && filterDict.measureName) {
            getSeveralReportsMeasuresFiltered(5000, 0, filterDict, tempMeasure) // max 1000 reports, no offset
                .then((data) => {
                    setJsonData(data); // TODO: add protection against API failure
                    // setJsonData(generateFakeData(400));
                    console.log('Setting JSON data:', data);
                })
                .catch((error) => {
                    console.log('Error:', error);
                });
        }
    }, [filterDict]);

    
    return (
        <div className={isSmallScreen ? "standard-module-screen-small" : "standard-module"}>
            <FilterListElement
                filterState={
                    {
                        date: ["year"],
                        read: ["both"],
                        finished: ["both"]
                    }
                }
                dictUpdateHandler={setFilterDict}
                filterFields="analytics"
            />
            <div>
                {jsonData ? (
                    <h2 className='module-title'>{filterDict.measureName}</h2>
                ) : (
                    <h2 className='module-title'>Choose a Measure</h2>
                )}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around' }}>
                {jsonData ? (
                    <div>
                        <GraphApiMeasureReports data={jsonData} graphParams={graphParams}/>
                    </div>
                ) : (
                    <div>...</div>
                )}
            </div>
        </div>
    );
}

export default MeasureGraphsModule;