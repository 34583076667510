import React, { useState, useEffect, useRef } from "react";
import Network from '../../../models/Network.js';

import * as vega from 'vega';
import * as vegaLite from 'vega-lite';
import * as vl from 'vega-lite-api';
import { Handler } from 'vega-tooltip';
// import { json } from "d3";

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')

const dark = '#3e3c38';

const config = {
    axis: {
        domain: false,
        tickColor: 'lightGray',
    },
    style: {
        'guide-label': {
            fontSize: 16,
            fill: dark
        },
        'guide-title': {
            fontSize: 24,
            fill: dark,
            labelLimit: 0
        }
    }
};

const colorScale = {
    domain: ['general-emergent', 'hypoglycemia', 'respiratory', 'stroke', 'syncope', 'trauma'],
    range: ['#E83838', '#8CF0C7', '#FFE347', '#4CC9F0', '#32CD32', '#000']
}

// setting up marks and channels
const viz = vl.markLine()
    .encode(
        // vl.x().fieldQ('scene_to_destination_time')
        vl.x().fieldT('creation_timestamp')
            // .scale({ zero: false })
            .title('Time Created')
            .timeUnit('monthdateyear') // bins the data by timeunit
            // .timeUnit('milliseconds')
            .axis({ tickCount: 5 }),
        vl.y().fieldQ('on_scene_to_end_odom')
            // .scale({ zero: false })
            .aggregate('average')
            .title('Transport Distance (miles)')
            // .axis({ tickCount: 6 }),
        // vl.color().fieldN('report_type')
        //     .title('Call Type')
        //     .scale(colorScale),
        // vl.tooltip().fieldN('report_id') // no tooltip for public graphs
    )

// registration
vl.register(vega, vegaLite, {
    view: { renderer: 'svg' },
    init: view => { view.tooltip(new Handler().call); }
});

const GraphReportsTS = ({ graphParams }) => {
    const containerRef = useRef(null);

    const [jsonData, setJsonData] = useState(null);
    const [marks, setMarks] = useState(null);
    const [vegaView, setVegaView] = useState(null);

    // function that gets multiple reports from the server
    const getSeveralReports = async (desiredNumber, reportOffset) => {
        
        const url = URLS.GET_SEVERAL_REPORTS_ANALYTICS;
        const dict = {
            // uid: user.uid,
            uid: 4,
            number_of_reports: desiredNumber,
            offset: reportOffset,
            CLIENT_ACCESS_KEY: key
        };

        let responseData;

        await Network.post(url, dict, (response) => {

            responseData = response.data;

            const extractedErrorCode = responseData.toString();
            const status = response.status

            if (Network.checkValidStatusAndResponse(response)) {
                // do something

            } else if (status == StatusCodes.INTERNAL_SERVER_ERROR) {
                console.log("Server Error")

            } else {
                console.log("Unknown Error")
            }
        });

        return responseData;
    };

    useEffect(() => {
        getSeveralReports(70, 0) // # reports, offset
            .then((data) => {
                console.log('data: ', data[0]);
                setJsonData(data);
                
                var vgSpec = vegaLite.compile(viz.data(data).width(500).height(400).transform(
                    {
                    "calculate": "datetime(datum.creation_timestamp)",
                    "as": "month_created"
                    }
                )).spec;

                const runtime = vega.parse(vgSpec);
                const view = new vega.View(runtime)
                    .renderer("svg")
                    .initialize(containerRef.current)
                    .hover(); // Enable hover interaction
        
                // Add Vega Tooltip handler
                new Handler().call(view);
        
                view.addEventListener('click', (event, item) => {
                    console.log('Point clicked');
                    console.log(event);
                    console.log(item); // this gets the data that I want
                });
                
                // // console.log('json data set: ', jsonData);
                // setMarks(viz
                //     .data(data)
                //     // .width(graphParams && graphParams.width !== null ? graphParams.width : 500)
                //     .width(500)
                //     // .height(window.innerHeight)
                //     // .height(graphParams && graphParams.height !== null ? graphParams.height : 450)
                //     .height(400)
                //     // .autosize({ type: 'fit', contains: 'padding' })
                //     // .autosize({ type: 'fit' })
                //     // .config(config)
                //     .transform(
                //         {
                //         "calculate": "datetime(datum.creation_timestamp)",
                //         "as": "month_created"
                //         }
                //     )
                // );
                // // console.log('marks set: ', marks);
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    }, []);

    useEffect(() => {
        if (marks) {
            marks.render().then(view => {
                setVegaView(view);
            }).catch(error => {
                console.error('Error rendering Vega view:', error);
            });
        }
    }, [marks]);


    return (
        <div>
            <div ref={containerRef} />
        </div>
    );

    return (
        <>
            {jsonData && vegaView ? (
                <div ref={(div) => {
                    if (div && vegaView && div.children.length === 0) {
                        div.appendChild(vegaView);
                    }
                }} />
            ) : (
                <div>Loading...</div>
            )}
        </>
    );
};

export default GraphReportsTS;