import React, { useState, useEffect } from 'react';
import Network from '../../../models/Network.js';

import './CheckListElement.css';

import TypeTag from '../tags/TypeTag.js';
import MedicTag from '../tags/MedicTag.js';
import ImpactAttributeTag from '../tags/ImpactAttributeTag.js';

const key = process.env.REACT_APP_CLIENT_ACCESS_KEY;
const URLS = require("../../../urls.js");

const StatusCodes = require('../../../status-codes/status-codes.js')
const ErrorCodes = require('../../../error-codes/error-codes.js')

const CheckFlagListElement = ({ data, onButtonClick }) => {
    const [overriddenStatus, setOverriddenStatus] = useState(data.manual_override);

    const handleButtonClick = () => {
        // Use the functional form of setState
        setOverriddenStatus(prevStatus => {
            const newStatus = prevStatus ? 0 : 1;
    
            // Pass updated status to parent component
            let flagToUpdate = {
                checkFlagData: data,
                overriddenStatus: newStatus,
                objectType: 'ai_flag'
            };
            // console.log('checkToUpdate (child):', checkToUpdate);
            onButtonClick(flagToUpdate);
    
            return newStatus;
        });
    
        console.log('overriddenStatus changed');
    };
    

    return (
        <div className="list-element" style={{ backgroundColor: '#fad4d4' }}>
            <div className="small-div" style={{ display: 'flex', flexDirection: 'column'}}>
                <div className='large-div' style={{ display: 'flex', flexDirection: 'row' }}>
                    <div className={0 === 0 ? 'medium-div' : 'small-div'}>
                        <TypeTag reportType={'protocol-flag'} isButton={false}/>
                        <ImpactAttributeTag attributeType={'documentation'} isButton={false}/>
                    </div>
                    <div className="large-div" style={{ justifyContent: 'center' }}>
                        <div className="element-name" style={{ fontSize: 'larger' }}> Narrative Suggestion </div>
                    </div>
                    {true && (
                        <div className="small-div" style={{ justifyContent: 'right' }}>
                            <button className="enter-button" onClick={handleButtonClick}>{overriddenStatus ? 'Reinstate' : 'Override'}</button>
                        </div>
                    )}
                    <div className="small-div" style={{ justifyContent: 'right' }}>
                        <div className="element-value" style={{ color: data.result ? '#29ba46' : '#e83838' }}></div>
                    </div>
                </div>
                

                {!overriddenStatus && ( // if overridden do not show issue message and failure mode
                    <>
                        <div className="small-div" style={{ marginTop: 20 }}>
                            <p><strong><u>Suggestion</u>:</strong> {data.generated_text}</p>
                        </div>
                        {data.failure_mode && ( // if failure mode is not null, show failure mode
                            <div className="small-div" style={{ marginTop: 20 }}>
                                <p><strong><u>Reason</u>:</strong> {data.failure_mode}</p> 
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default CheckFlagListElement;